.accounts-table .ant-table-cell {
    padding: 16px 24px !important;
}

.accounts-table th:last-child {
    background: #ffffff !important;
}

.accounts-table td:first-child {
    background: #fafafa !important;
}