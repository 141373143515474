@import '~antd/dist/antd.css';

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout-content {
  padding: 24px 0;
  min-height: calc(100vh - 65px);
}

.ant-table-thead {
  position: sticky !important;
  top: 0;
  z-index: 10;
}

.ant-table-title {
  padding: 16px 0 !important;
}

.ant-table-wrapper:not(.allow-wrap) th.ant-table-cell {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-upload-list-item-info::before {
  top: 0;
  left: 0;
}

.disabled-row > td {
  background-color: #f6e7e7;
}

.yellow-row > td {
  background-color: #fdfce5;
}

.red-row > td {
  background-color: #f6e7e7 !important;
}

.red-row:hover > td {
  background-color: #f6e7e7 !important;
}

.text-cell-ellipsis {
  max-width: 200px;
}

.no-wrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ant-input-number-group-wrapper {
  width: 100%;
}

.no-margin {
  margin: 0 !important;
}

.ant-layout-sider-trigger {
  position: sticky;
}

.ant-table-wrapper:not(.big) .ant-table-cell {
  padding: 8px !important;
}

.strikeout-table {
  border-collapse: collapse;
}

.strikeout-table td {
  position: relative;
  padding: 5px 10px;
}

.strikeout-table tr.strikeout {
  background: #f5f5f5;
}

.strikeout-table tr.strikeout td:before {
  content: ' ';
  position: absolute;
  top: 50%;
  left: 0;
  border-bottom: 1px solid #111;
  z-index: 3;
  width: 100%;
}

.transfer-expired > td {
  background-color: #f6e7e7 !important;
}
